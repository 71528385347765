<h4>Einschreibung nicht erfolgreich</h4>
<p *ngIf="issue === 1">
  Ihre Krankenkasse bietet den gewählten Vertrag zur besonderen Versorgung noch nicht an.
</p>
<p *ngIf="issue !== 1">
  Zur Teilnahme an Verträgen zur besonderen Versorgung muss Ihre behandelnde Ärztin individuelle Verträge
  mit jeder Krankenkasse schließen. Ihr behandelnder Arzt hat diesen noch nicht abgeschlossen.
</p>
<p>
  Sollten Sie Fragen zur Teilnahme an Verträgen zur besonderen Versorgung haben, melden Sie sich bei Ihrem behandelnden
  Arzt, Ihrer Krankenkasse oder schreiben Sie uns eine E-Mail an
  <a href="mailto:service@sanakey-portal.de">service@sanakey-portal.de</a>.
</p>
<div class="row mt-1">
  <div class="col text-center">
    <button class="btn btn-primary m-lg-5" (click)="zurueck.emit()">Zurück</button>
  </div>
</div>
