<div class="modal-body">
  <p class="p-3 text-center">
    <button type="button" class="close p-2 btn" aria-label="schließen"
            (click)="alsWeggeklicktMarkieren()">
      <h3 aria-hidden="true">&times;</h3>
    </button>
  </p>
  <p class="text-center">
    <img src="assets/logos/novitas-bkk.png" alt="NOVITAS BKK" class="img-fluid logo">
  </p>
  <h4 class="p-3 text-center">
    Neuer Versorgungsvertrag zum Hautkrebsscreening verfügbar!
  </h4>
  <p class="p-3 text-justify">
    Ab dem 01.01.2025 steht Ihnen der neue Versorgungsvertrag mit der Novitas BKK zur Verfügung.
  </p>
  <div class="p-3">
    <table class="table">
      <thead>
      <tr>
        <th>Vergütungsposition</th>
        <th>ICD</th>
        <th>Hinweis</th>
        <th>Entgelthöhe</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>HKSBIS34</td>
        <td>Z12.8 (G)</td>
        <td>
          Versicherte<br>
          15 bis 34 Jahre<br>
          1x je Kalenderjahr abrechenbar
        </td>
        <td>37,00 Euro</td>
      </tr>
      <tr>
        <td>HKSAB35</td>
        <td>Z12.8 (G)</td>
        <td>
          Versicherte<br>
          ab 35 Jahren<br>
          1x jedes zweite Kalenderjahr abrechenbar
        </td>
        <td>37,00 Euro</td>
      </tr>
      </tbody>
    </table>
  </div>
  <p class="p-3 text-center">
    <span class="form-check">
      <label>
      <input type="checkbox" [(ngModel)]="bestaetigen">
        &nbsp;Nicht noch ein Mal anzeigen</label>
    </span>
    <span class="btn btn-primary" (click)="schliessen(bestaetigen);geheZuVertraegen()">Zur Vertragseinschreibung</span>
  </p>
</div>
