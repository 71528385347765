import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {select, Store} from '@ngrx/store';
import {getHeaderFeatureState} from '../reducers/header.reducer';
import {FooterModalsService} from '../shared/footer-modals.service';
import {SideBarService} from '../helpers/side-bar.service';
import {faBars} from '@fortawesome/free-solid-svg-icons';
import {getSidebarFeatureState} from '../reducers/sidebar.reducer';
import {animate, sequence, state, style, transition, trigger} from '@angular/animations';
import {LoginBoxComponent} from '../landing/login-box/login-box.component';
import {BenutzermanagementAPIService} from '../api/benutzermanagement/benutzermanagement-api.service';
import {getBenutzerInfoFeatureState} from '../reducers/authinfo.reducer';
import {take} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
  selector: 'san-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss'],
  animations: [
    trigger('scale', [
      // ...
      state('hidden', style({
        // transform: 'scale(0)',
        'max-width': 0,
      })),
      state('visible', style({
        // transform: 'scale(1)',
        'max-width': '100px',
      })),
      transition('visible => hidden', [
        sequence([
          animate('0.2s ease-in-out'),
        ]),
      ]),
      transition('hidden => visible', [
        sequence([
          animate('0.2s ease-in-out')
        ]),
      ]),
    ]),
  ]
})
export class BaseLayoutComponent implements OnInit, OnDestroy {
  @ViewChild('impressum') impressum;
  @ViewChild('datenschutz') datenschutz;
  @ViewChild('nutzungsbedingungen') nutzungsbedingungen;
  @ViewChild('auth') auth;
  @ViewChild('processError') processError;

  iconToggleSidebar = faBars;

  showInfoBoxButton = false;
  showSidebar = false;
  showAccountBoxButton = false;
  private authModalRef: NgbModalRef;
  private headerState$: Subscription;
  private sidebarState$: Subscription;
  linkTopLogo = '/';
  logoLeft = false;
  customLogo: string = null;
  customLogoAlt = '';
  customMainLogo: string = null;
  customMainLogoAlt = '';
  customMainLogoLink = null;
  customMainLogoStyle = {'background-image': ''};
  private processErrorRef = null;

  currentYear: number = new Date().getFullYear();


  constructor(private route: ActivatedRoute, public modalService: NgbModal, private store: Store,
              private changeDectection: ChangeDetectorRef, private footerService: FooterModalsService,
              public sidebarService: SideBarService) {
    this.footerService.setInstance(this);
  }

  ngOnDestroy(): void {
    this.headerState$?.unsubscribe();
    this.sidebarState$?.unsubscribe();
  }

  ngOnInit(): void {
    this.headerState$ = this.store.pipe(select(getHeaderFeatureState)).subscribe((headerState) => {
      this.showInfoBoxButton = headerState.header.infoIconVisible;
      this.showAccountBoxButton = headerState.header.accountButtonVisible;
      this.linkTopLogo = (headerState.header.accountButtonVisible) ? '/dashboard' : '/';
      this.logoLeft = headerState.header.logoPosition === 'left';
      this.customLogo = headerState.header.customLogo;
      this.customLogoAlt = (headerState.header.customLogoAlt) ? headerState.header.customLogoAlt : '';
      this.customMainLogo = headerState.header.customMainLogo;
      this.customMainLogoAlt = headerState.header.customMainLogoAlt;
      this.customMainLogoLink = headerState.header.customMainLogoLink;
      if (this.customMainLogo) {
        this.customMainLogoStyle['background-image'] = `url('${this.customMainLogo}')`;
      }
      this.changeDectection.detectChanges();
    });
    this.sidebarState$ = this.store.pipe(select(getSidebarFeatureState)).subscribe((sidebarState) => {
      this.showSidebar = sidebarState.sidebar.sidebarEnabled;
      this.changeDectection.detectChanges();
    });
  }

  openImpressum() {
    this.modalService.open(this.impressum, {size: 'xl', centered: true});
  }

  openDatenschutz() {
    this.modalService.open(this.datenschutz, {size: 'xl', centered: true});
  }

  openNutzung() {
    this.modalService.open(this.nutzungsbedingungen, {size: 'xl', centered: true});
  }

  openAuth() {
    if (this.authModalRef) {
      // modal already opened
      return;
    }
    this.authModalRef = this.modalService.open(LoginBoxComponent, {size: 'm', centered: true, backdrop: 'static'});
    (this.authModalRef.componentInstance as LoginBoxComponent).showTitle = 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.';
    this.store.select(getBenutzerInfoFeatureState).pipe(take(1)).subscribe(s => {
      (this.authModalRef.componentInstance as LoginBoxComponent).fillUsername(s.auth.username);
    });
  }

  closeAuth() {
    this.authModalRef.close();
  }

  openProcessError() {
    if (this.modalService.hasOpenModals()) {
      return;
    }

    if (this.processErrorRef) {
      // already opened
      return;
    }
    this.processErrorRef = this.modalService.open(this.processError, {size: 'lg', centered: true});
  }

  closeProcessError() {
    if (this.processErrorRef) {
      this.processErrorRef.close();
    }
  }
}
