import {Component, HostBinding, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {faBars, faEuroSign, faFileAlt, faPlay, faPlus, faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {environment} from 'src/environments/environment';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {
    ArztDatenResponseMitInputForm,
    ClientService, PreviewResponse,
} from '../api/client.service';
import {Store} from '@ngrx/store';
import {AppToastService} from '../../toast/app-toast.service';
import {StepperComponent} from '../../shared/stepper/stepper.component';
import {StepComponent} from '../../shared/stepper/step/step.component';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {HideCustomLogoAction} from '../../reducers/header.reducer';
import {interval} from 'rxjs';
import {FormControl, FormGroup, ValidationErrors, Validators, AbstractControl, ValidatorFn} from '@angular/forms';
import {
    ComponentCanDeactivate,
    FormControlWithHints,
    gueltigeKVNR,
    noDateBefore,
    noFutureDate
} from '../../shared/form-helpers';
import {InfotextArztdatenQRTNEComponent} from './infotext-arztdaten-qrtne/infotext-arztdaten-qrtne.component';

function newForm() {
    return new FormGroup({
        versicherten_nummer: new FormControl(null, [Validators.required, gueltigeKVNR]),
        vorname: new FormControl(null, [Validators.required]),
        nachname: new FormControl(null, [Validators.required]),
        geschlecht: new FormControl(null, [Validators.required]),
        geburtsdatum: new FormControl(null, [Validators.required, noFutureDate]),
        krankenkasse: new FormControl(null, [Validators.required]),
        email: new FormControl(null, [Validators.required, Validators.email]),
    });
}

export function badTimeValidator(tnzCheck$: BehaviorSubject<{ae: Date, kkBeitritt: Date, vertrag: Date} | null>): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const tnzCheck = tnzCheck$.getValue();
    if (!control.value) {
      return null;
    }

    const value = new Date(control.value);
    const vertrag = new Date(tnzCheck.vertrag);
    if (vertrag) {
      vertrag.setSeconds(0);
      vertrag.setMinutes(0);
      vertrag.setHours(0);
    }

    const aeValid = new Date(tnzCheck.ae) <= value;
    const kkBeitrittValid = new Date(tnzCheck.kkBeitritt) <= value;
    const vertragValid = vertrag <= value;

    const tnzValid = aeValid && kkBeitrittValid && vertragValid;

    if (!tnzValid) {
      if (!aeValid) {
        return {badTime: 'der Arzt Einschreibung'};
      }
      else if (!kkBeitrittValid) {
        return {badTime: 'des Krankenkassen Beitritts'};
      }
      else {
        return {badTime: 'des Vertrages'};
      }
    }
    return null;
  };
}

@Component({
    selector: 'san-qrtnedaten-eingabe',
    templateUrl: './qrtnedaten-eingabe.component.html',
    styleUrls: ['./qrtnedaten-eingabe.component.scss']
})
export class QRTNEDatenEingabeComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
    @HostBinding('class.d-block') dblock = true;
    @HostBinding('class.h-100') h100 = true;

    @ViewChild('stepperComponent') stepperComponent: StepperComponent;
    // Steps:
    @ViewChild('stepLogin') stepLogin: StepComponent;
    @ViewChild('stepZustimmung') stepZustimmung: StepComponent;
    @ViewChild('stepZustimmungBestaetigung') stepZustimmungBestaetigung: StepComponent;
    @ViewChild('stepZustimmungBestaetigungNachLogin') stepZustimmungBestaetigungNachLogin: StepComponent;
    @ViewChild('stepWiderruf') stepWiderruf: StepComponent;
    @ViewChild('stepWiderrufBestaetigung') stepWiderrufBestaetigung: StepComponent;
    @ViewChild('stepUngueltigeEinschreibung') stepUngueltigeEinschreibung: StepComponent;
    @ViewChild('infotextArztdatenQRTNEComponent') infotextArztdatenQRTNEComponent: InfotextArztdatenQRTNEComponent;
    @ViewChild('stepFehlerNichtEingeschrieben') stepFehlerNichtEingeschrieben: StepComponent;
    @ViewChild('stepTnz') stepTNZBEginn: StepComponent;
    @ViewChild('stepBasisdatenZwei') stepBasisDatenZwei: StepComponent;

    spinnerIcon = faSpinner;
    iconAdd = faPlus;
    iconStart = faPlay;
    iconBars = faBars;
    iconEuros = faEuroSign;
    iconDokumente = faFileAlt;

    environment = environment;
    datenschutzAkzeptiert = false;
    loading = false;

    sending = false;
    wrongCredentials = false;

    arztDatenResponseMitInputForm: ArztDatenResponseMitInputForm = {
        input_form: newForm(),
        arzt: null,
        vertrag: null,
        krankenkassen: [],
        vertrag_name: '',
        tnzCheck$: new BehaviorSubject(null),
    };

    state: PreviewResponse;

    showLinkToWiderruf = true;

    // tslint:disable-next-line:variable-name
    tnz_beginn = '';
    // tslint:disable-next-line:variable-name
    tnz_form = new FormGroup({
        tnz_beginn: new FormControl('', [Validators.required,
          badTimeValidator(this.arztDatenResponseMitInputForm.tnzCheck$)]),
    }); // TODO: custom validator for bad time

    private interval: Observable<number>;
    private intervalSub$: Subscription;
    userParams: {
        tnz_beginn: string;
        uuid: string,
        versorgungsVertragID: number,
        vertragsgruppe: number,
        action?: string
    } = {
        tnz_beginn: '',
        versorgungsVertragID: 0,
        vertragsgruppe: 0,
        uuid: '',
        action: ''
    };
    krankenkassen: { name: string, id: number }[] = [];
    login = false;
    previewState: PreviewResponse;
    creds: { versicherten_nummer: string; geburtsdatum: string; tnz_beginn: string } = null;

    issue = 0;

    constructor(private activatedRoute: ActivatedRoute, private apiClient: ClientService, private store: Store,
                private toastService: AppToastService, private router: Router) {
        // noinspection PointlessArithmeticExpressionJS
        this.interval = interval(1 /* min */ * 2 /* s */ * 1000 /* ms */);
    }

    ngOnDestroy(): void {
        this.intervalSub$?.unsubscribe();
        this.store.dispatch(new HideCustomLogoAction());
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe((value: Params) => {
            this.userParams.uuid = value.uuid;
            this.userParams.versorgungsVertragID = value.versorgungsvertragId || 0;
            this.userParams.vertragsgruppe = value.vertragsgruppe || 0;
            this.userParams.action = value.action;
            this.userParams.tnz_beginn = value.tnz_beginn;
            if (this.userParams.action === 'login') {
                this.login = true;
            }
            setTimeout(() => {
                this.loading = false;
            }, 50);
        });
    }

    einschreiben() {
        if (this.sending) {
            return;
        }

        if (!this.arztDatenResponseMitInputForm.input_form.valid) {
            this.toastService.show('Ein Fehler ist aufgetreten',
                'Ihre Daten enthalten Fehler.', 'danger');
            return;
        }

        this.sending = true;

        const patientenDaten = this.arztDatenResponseMitInputForm.input_form.value;
        if (!Number.isInteger(patientenDaten.krankenkasse)) {
            patientenDaten.krankenkasse = patientenDaten.krankenkasse.id;
        }

        this.apiClient.patientenSelbstEinschreibungSignV2({
            patient_akzeptiert_datenschutzbestimmungen: true,
            patient_akzeptiert_sanakeyportal_datenschutzbestimmungen: true,
            patient_akzeptiert_sanakeyportal_nutzungsbedingungen: true,
            patient_akzeptiert_teilnahmebedingungen: true,
            patientenDaten,
            uuid: this.userParams.uuid,
            versorgungsvertragId: this.userParams.versorgungsVertragID,
            vertragsgruppe: this.userParams.vertragsgruppe,
            tnz_beginn: this.tnz_beginn,
        }).subscribe((value) => {
            this.tnz_beginn = value.beginn_teilnahmezeitraum;
            this.showLinkToWiderruf = false;
            this.stepperComponent.goToStepViaComponent(this.stepZustimmungBestaetigung, true);
            this.startStatePolling();
        }, error => {
            this.toastService.show('Ein Fehler ist aufgetreten',
                'Die Einschreibung konnte nicht bestätigt werden. Bitte versuchen Sie es später erneut', 'danger');
            if (error.status === 404) {
                this.goToErrorArztNichtEingeschrieben();
            } else if (error.status === 418) {
                // TNZ Datum < Datum der Arzt Einschreibung
                this.toastService.show('Ein Fehler ist aufgetreten',
                    'Ihr TNZ Beginn ist nicht korrekt. Bitte überprüfen Sie Ihre Eingaben.', 'danger');
                this.stepperComponent.goToStepViaComponent(this.stepTNZBEginn, true);
            } else if (error.status === 417) {
                // DigiTNE2 nicht aktiv für Vertrag
                this.toastService.show('Ein Fehler ist aufgetreten',
                    'Für die gewählte Krankenkasse ist die Einschreibung nicht möglich.', 'danger');
            }
            this.sending = false;
        });
    }

    anmeldenAngeklickt() {
        this.infotextArztdatenQRTNEComponent.ladeArztDaten();
        this.stepperComponent.goToStep(1);
    }

    checkLogin($event: { versicherten_nummer: string; geburtsdatum: string; }) {
        const creds = {...$event, tnz_beginn: this.userParams.tnz_beginn};
        this.apiClient.patientenSelbstEinschreibungPreview(creds).subscribe(value => {
            this.creds = creds;
            this.state = value;
            this.onSuccessfulLogin();
        }, error => {
            this.showLoginError();
        });
    }

    onSuccessfulLogin() {
        if (this.state.canceled) {
            this.stepperComponent.goToStepViaComponent(this.stepWiderrufBestaetigung, true);
        } else {
            this.stepperComponent.goToStepViaComponent(this.stepZustimmungBestaetigungNachLogin, true);
        }
        this.startStatePolling();
    }

    abmelden() {
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate(['/', 'patientenselbsteinschreibung']);
        });
        this.toastService.show('Abgemeldet',
            'Sie wurden abgemeldet. Bitte schließen Sie diesen Tab/dieses Fenster nun',
            'success'
        );
    }

    widerruf() {
        this.stepperComponent.goToStepViaComponent(this.stepWiderruf, true);
    }

    downloadTNE() {
        this.initCreds();
        this.apiClient.patientenSelbstEinschreibungTNE(this.creds).subscribe((value) => {
            this.downloadData(value.b64data, value.filename);
        });
    }

    private downloadData(pdfb64: string, filename: string) {
        const link = document.createElement('a');
        link.href = `data:application/pdf;base64,${pdfb64}`;
        link.download = filename;
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

        setTimeout(() => {
            link.remove();
        }, 100);
    }

    widerrufBestaetigen() {
        if (this.sending) {
            return;
        }
        this.sending = true;

        this.apiClient.patientenSelbstEinschreibungCancel(this.creds)
            .subscribe((value) => {
                this.stepperComponent.goToStepViaComponent(this.stepWiderrufBestaetigung, true);
                this.startStatePolling();
            }, error => {
                this.toastService.show('Ein Fehler ist aufgetreten',
                    'Die Einschreibung konnte nicht widerrufen werden. Bitte versuchen Sie es später erneut', 'danger');
            });
    }

    private startStatePolling() {
        this.intervalSub$?.unsubscribe();
        this.intervalSub$ = this.interval.subscribe(() => {
            this.refreshState();
        });
    }

    private initCreds() {
        if (!this.creds || !this.creds.tnz_beginn) {
            this.creds = {
                versicherten_nummer: this.arztDatenResponseMitInputForm.input_form.get('versicherten_nummer').value,
                geburtsdatum: this.arztDatenResponseMitInputForm.input_form.get('geburtsdatum').value,
                tnz_beginn: this.tnz_beginn,
            };
        }
    }

    private refreshState() {
        this.initCreds();
        this.apiClient.patientenSelbstEinschreibungPreview(this.creds).subscribe((value) => {
            this.previewState = value;
            if (value.teilnahmeerklaerung_abrufbar) {
                this.intervalSub$?.unsubscribe();
            }
        });
    }

    private showLoginError() {
        this.toastService.show(
            'Fehlerhafte Daten',
            'Bitte überprüfen Sie Ihre Daten. Wir konnten keine Einschreibung mit den angegebenen Daten finden.',
            'danger');
    }

    goToErrorArztNichtEingeschrieben(issue: number = 0) {
        this.issue = issue;
        this.stepperComponent.goToStepViaComponent(this.stepFehlerNichtEingeschrieben, true);
    }

    setTnz() {
        this.tnz_beginn = this.tnz_form.get('tnz_beginn').value;
        return true;
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (!this.canDeactivate()) {
            $event.returnValue = true;
        }
    }

    canDeactivate(): boolean | Observable<boolean> {
        const changesDone = this.tnz_form.dirty || this.arztDatenResponseMitInputForm.input_form.dirty;
        const polling = this.intervalSub$ && !this.intervalSub$.closed;
        return changesDone && !polling;
    }

    arztBestaetigtGeklickt() {
        this.stepperComponent.next();
    }

    zurueck() {
        this.stepperComponent.zurueck();
    }

    zurueckVonError() {
      this.stepperComponent.goToStepViaComponent(this.stepBasisDatenZwei, true);
    }
}
