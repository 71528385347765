import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {gueltigeKVNR, noFutureDate} from '../../../shared/form-helpers';
import {ArztDatenResponse, ClientService, PreviewResponse} from '../../api/client.service';
import {merge, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {NgbTypeaheadSelectItemEvent} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'san-basis-daten-eingabe-zwei',
  templateUrl: './basis-daten-eingabe-zwei.component.html',
  styleUrls: ['./basis-daten-eingabe-zwei.component.scss']
})
export class BasisDatenEingabeZweiComponent implements OnInit {
  @HostBinding('class.d-block') dblock = true;
  @HostBinding('class.h-100') h100 = true;

  @Input() state: ArztDatenResponse;
  @Input() baseDataForm: FormGroup;
  @Input() krankenkassen: { name: string, id: number }[];
  @Input() userParams: {
    tnz_beginn: string;
    uuid: string,
    versorgungsVertragID: number,
    vertragsgruppe: number,
    action?: string
  };

  @Output() ready = new EventEmitter();
  @Output() zurueck = new EventEmitter();
  @Output() invalid = new EventEmitter();

  krankenkasse: any;

  krankenkasseAuswahlConfig = {
    titleFocused$: new Subject<string>(),
    titleClicked$: new Subject<string>(),
    titleSelected$: new Subject<string>(),
    // noinspection JSUnusedGlobalSymbols
    titleAuswahl: (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
      // const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
      const inputFocus$ = this.krankenkasseAuswahlConfig.titleFocused$;
      return merge(debouncedText$, inputFocus$, this.krankenkasseAuswahlConfig.titleClicked$, this.krankenkasseAuswahlConfig.titleSelected$)
        .pipe(
          map(term => this.krankenkassen.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1)));
      // tslint:disable-next-line:semicolon
    }
  };

  formatter = (x: { name: string }) => x.name;

  constructor(private clientService: ClientService) {
  }

  ngOnInit(): void {
  }

  checkReady() {
    this.baseDataForm.get('versicherten_nummer').markAsDirty();
    this.baseDataForm.get('email').markAsDirty();
    this.baseDataForm.get('krankenkasse').markAsDirty();

    const formValid = this.baseDataForm.get('versicherten_nummer').valid
      && this.baseDataForm.get('email').valid
      && this.baseDataForm.get('krankenkasse').valid;

    if (!formValid) {
      return;
    }

    this.clientService.getKrankenkasseAvailability(
      this.userParams.uuid,
      this.userParams.versorgungsVertragID,
      this.userParams.vertragsgruppe,
      this.baseDataForm.get('krankenkasse').value.id).subscribe((r) => {
        if (r.issue === 0) {
          this.clientService.getVertragDaten(
            this.userParams.uuid,
            this.userParams.versorgungsVertragID,
            this.userParams.vertragsgruppe,
            this.baseDataForm.get('krankenkasse').value.id).subscribe((response) => {
              this.state.vertrag = response.vertrag;
          });
          this.state.tnzCheck$.next({ae: r.ae, vertrag: r.vertrag, kkBeitritt: r.kkBeitritt});
          this.ready.emit();
        }
        else {
          this.invalid.emit(r.issue);
        }
    });

  }

  checkReadyOld() {
    for (const controlName in this.baseDataForm.controls) {
      if (!this.baseDataForm.controls.hasOwnProperty(controlName)) {
        continue;
      }
      this.baseDataForm.controls[controlName].markAsDirty();
    }
    if (this.baseDataForm.valid) {
      this.ready.emit();
    }
  }

  setKrankenkasse($event: NgbTypeaheadSelectItemEvent<any>) {
    this.baseDataForm.patchValue({krankenkasse: $event.item});
  }
}
