<div class="container h-100">
  <h5>Ihr Behandlungsbeginn</h5>
  <p>
    Bitte erfassen Sie nun das Behandlungsdatum, das ist der Tag an dem Sie in der Arztpraxis behandelt werden.
  </p>
  <div class="row row-cols-1">
    <div class="col">

      <form class="p-3" (ngSubmit)="checkReady()">

        <div class="input-group mt-3">
          <label class="small w-100" title="Ihr Geburtstag; erforderlich">
            Ihr Behandlungsbeginn*
          </label>
          <input type="date" class="form-control"
                 [formControl]="tnzBeginn"
                 aria-label="Ihr Behandlungsbeginn"
                 autocomplete="none">
        </div>
        <san-form-input-error
          [visible]="!tnzBeginn.pristine && tnzBeginn.errors && tnzBeginn.errors.required"
          message="Bitte geben Sie Ihren Behandlungsbeginn an"></san-form-input-error>
        <san-form-input-error
          [visible]="!tnzBeginn.pristine && tnzBeginn.errors && tnzBeginn.errors.badTime"
          message="Das gewählte Behandlungsdatum liegt vor dem Beginn {{ tnzBeginn.errors?.badTime }}.
          Bitte prüfen Sie das ausgewählte Behandlungsdatum."></san-form-input-error>
      </form>
    </div>
    <div class="row mt-1">
      <div class="col text-center">
        <button class="btn btn-primary m-lg-5" (click)="zurueck.emit()">Zurück</button>
      </div>
      <div class="col text-center">
        <button class="btn btn-primary m-lg-5" (click)="checkReady()">Weiter</button>
      </div>
    </div>
  </div>
</div>
