import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ArztDatenResponseMitInputForm} from '../../api/client.service';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'san-zustimmung-digi-tne-zwei',
  templateUrl: './zustimmung-digi-tne-zwei.component.html',
  styleUrls: ['./zustimmung-digi-tne-zwei.component.scss']
})
export class ZustimmungDigiTneZweiComponent implements OnInit {
  @Input() state: ArztDatenResponseMitInputForm;
  @Input() sending = false;

  @Output() bestaetigenClicked = new EventEmitter();
  @Output() zurueck = new EventEmitter();

  spinnerIcon = faSpinner;

  bed1 = false;
  bed2 = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  onBestaetigenClicked() {
    if (this.sending || !this.bed1 || !this.bed2) {
      return;
    }
    this.bestaetigenClicked.emit();
  }

}
