import { Component } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BenutzermanagementAPIService} from '../../api/benutzermanagement/benutzermanagement-api.service';
import {AppToastService} from '../../toast/app-toast.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

@Component({
  selector: 'san-bkkhks-vertrag',
  templateUrl: './bkk-textilgruppe-fusion.component.html',
  styleUrls: ['./bkk-textilgruppe-fusion.component.scss']
})
export class BkkTextilgruppeFusion {
  static ID = 'BKK Textilgruppe Fusion';
  bestaetigen = false;

  constructor(private activeModal: NgbActiveModal, private bmClient: BenutzermanagementAPIService,
              private toastService: AppToastService, private http$: HttpClient, private router: Router) {
  }

  ngOnInit(): void {
  }

  alsGelesenMarkieren() {
    this.activeModal.dismiss();
    this.bmClient.setPopupViewed(BkkTextilgruppeFusion.ID, false).subscribe();
  }

  alsWeggeklicktMarkieren() {
    if (this.bestaetigen) {
      this.alsGelesenMarkieren();
    }
    this.activeModal.dismiss();
    this.bmClient.setPopupViewed(BkkTextilgruppeFusion.ID, true).subscribe();
  }

  schliessen(bestaetigen: boolean) {
    if (bestaetigen) {
      this.alsGelesenMarkieren();
    } else {
      this.alsWeggeklicktMarkieren();
    }
  }
}
