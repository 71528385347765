import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'san-fehler-arzt-nicht-eingeschrieben',
  templateUrl: './fehler-arzt-nicht-eingeschrieben.component.html',
  styleUrls: ['./fehler-arzt-nicht-eingeschrieben.component.scss']
})
export class FehlerArztNichtEingeschriebenComponent implements OnInit {
  @Input() issue = 0;

  @Output() zurueck = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
