<div class="modal-body">
  <p class="p-3 text-center">
    <button type="button" class="close p-2 btn" aria-label="schließen"
            (click)="alsWeggeklicktMarkieren()">
      <h3 aria-hidden="true">&times;</h3>
    </button>
  </p>
  <p class="text-center">
    <img src="assets/logos/bkkhof.png" alt="BKK HOF" class="img-fluid logo">
    <img src="assets/icons/png/arrow.png" alt="ARROW" class="img-fluid logo">
    <img src="assets/logos/mhplus.png" alt="MHPLUS BKK" class="img-fluid logo">
  </p>
  <h5 class="p-3 text-center header-colour mb-0">
    Wichtige Information zur Fusion der BKK Textilgruppe Hof und mhplus BKK
  </h5>
  <p class="p-3 text-justify paragraph-closer">
    Sehr geehrte Damen und Herren Doctores,
    <br>
    sehr geehrte Praxis-Mitarbeiter*innen,
  </p>
  <p class="p-3 text-justify paragraph-closer">
    wir möchten Sie darüber informieren, dass die BKK Textilgruppe Hof zum 01.01.2025
    mit der mhplus BKK fusionierte.
  </p>
  <p class="p-3 text-justify paragraph-closer">
    Bitte beachten Sie, dass bei der Erfassung von Patienten  der BKK Textilgruppe Hof
    darauf geachtet werden muss, dass diese jetzt bei der mhplus BKK hinterlegt werden.
    Dies ist wichtig, um einen reibungslosen Übergang und die Fortführung der Abrechnung Ihrer Leistungen sicherzustellen.
  </p>
  <p class="p-3 text-justify paragraph-closer">
    Bei Fragen oder Anliegen stehen wir Ihnen jederzeit gerne zur Verfügung.
  </p>
  <p class="p-3 text-justify paragraph-closer">
    Die aktualisierte Liste der teilnehmenden Krankenkassen am BKK-Hautkrebsscreening u35 können Sie unter folgenden Link abrufen:
  </p>
  <p class="p-3 text-justify paragraph-closer">
    <a href="https://l.sanakey-portal.de/Teilnehmende-BKKen">Teilnehmende-BKKen</a>
  </p>
  <p class="p-3 text-justify paragraph-closer">
    Ihr Sanakey Service-Team
  </p>
  <p class="p-3 text-center">
    <span class="btn btn-primary float-end" (click)="schliessen(bestaetigen)">Bestätigen</span>
  </p>
</div>
