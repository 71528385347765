import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'san-form-input-error',
  templateUrl: './form-input-error.component.html',
  styleUrls: ['./form-input-error.component.scss']
})
export class FormInputErrorComponent implements OnInit {
  @Input() visible = undefined;
  @Input() message: string = null;
  @Input() messages: string[] = null;
  @Input() controlName: string;
  @Input() errorName: string;

  // private form: FormGroup;
  // private control: FormControl;

  constructor(
    // private controlContainer: ControlContainer
  ) {
  }

  ngOnInit(): void {
    // this.form = this.controlContainer.control as FormGroup;
    // this.control = this.form?.get(this.controlName) as FormControl;
  }

}
